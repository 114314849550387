import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';

export const VerticalPadding = ({
  block,
}: BlockComponentProps<'marketing-website.vertical-padding'>) => (
  <section>
    <div style={{ height: `${block.pixels}px` }} />
  </section>
);
